
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@use './app/app.component-theme' as app-component;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();


// * LIGHT THEME (DEFAULT) -----------------------------------------------------

$light-theme-primary: (
    50: #d0d0d0,
    100: #d6d6d6,
    200: #dbdbdb,
    300: #e0e0e0,
    400: #e5e5e5,
    500: #eaeaea,
    600: #efefef,
    700: #f4f4f4,
    800: #f9f9f9,
    900: #fefefe,
    contrast: (
        50: rgba(black, 0.1),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87),
        500: rgba(black, 0.87),
        600: rgba(black, 0.87),
        700: rgba(black, 0.87),
        800: rgba(black, 0.87),
        900: rgba(black, 0.87),
    )
);

$light-theme-accent: (
    // Main
    100: #487eb0,
    200: #7bade2,
    300: #045280,
    // Support
    400: #484ab0,
    500: #7c76e3,
    600: #002280,
    // Brand
    700: #3498db,
    800: #73c9ff,
    900: #006aa9,
    contrast: (
        // Main
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(white, 0.87),
        // Support
        400: rgba(white, 0.87),
        500: rgba(black, 0.87),
        600: rgba(white, 0.87),
        // Brand
        700: rgba(black, 0.87),
        800: rgba(black, 0.87),
        900: rgba(white, 0.87)
    )
);

$light-theme-warn: (
    100: #e74c3c,   // Error
    200: #FFCC00,   // Warning
    300: #4BB543,   // Success
    400: #1B9CFC,   // Information
    contrast: (
        100: rgba(white, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87)
    )
);

// * DARK THEME ----------------------------------------------------------------

$dark-theme-primary: (
    50: #080808,
    100: #121212,
    200: #1F1F1F,
    300: #242424,
    400: #262626,
    500: #292929,
    600: #2E2E2E,
    700: #313131,
    800: #363636,
    900: #383838,
    contrast: (
        50: rgba(white, 0.1),
        100: rgba(white, 0.87),
        200: rgba(white, 0.87),
        300: rgba(white, 0.87),
        400: rgba(white, 0.87),
        500: rgba(white, 0.87),
        600: rgba(white, 0.87),
        700: rgba(white, 0.87),
        800: rgba(white, 0.87),
        900: rgba(white, 0.87),
    )
);

// Use this to complete support colours
// Use 'analogous' option
// https://www.sessions.edu/color-calculator/

$dark-theme-accent: (
    // Main
    100: #487eb0,
    200: #7bade2,
    300: #045280,
    // Brand
    400: #3498db,
    500: #73c9ff,
    600: #006aa9,
    // Support
    700: #484ab0,
    800: #7c76e3,
    900: #002280,
    
    contrast: (
        // Main
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(white, 0.87),
        // Brand
        400: rgba(black, 0.87),
        500: rgba(black, 0.87),
        600: rgba(white, 0.87),
        // Support
        700: rgba(white, 0.87),
        800: rgba(black, 0.87),
        900: rgba(white, 0.87)
    )
);

$dark-theme-warn: (
    100: #e74c3c,   // Error
    200: #FFCC00,   // Warning
    300: #4BB543,   // Success
    400: #1B9CFC,   // Information
    contrast: (
        100: rgba(white, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: rgba(black, 0.87)
    )
);

// For light theme
$light-theme-background-primary: mat.define-palette($light-theme-primary, 
        100, 200, 300, 400);
$light-theme-background-accent: mat.define-palette($light-theme-accent, 
        100, 200, 300, 400);
$light-theme-background-warn: mat.define-palette($light-theme-warn,
        100, 200, 300, 400);


$light-theme: mat.define-light-theme((
  color: (
    primary: $light-theme-background-primary,
    accent: $light-theme-background-accent,
    warn: $light-theme-background-warn,
  )
));

// For dark theme
$dark-theme-background-primary: mat.define-palette($dark-theme-primary,
    100, 200, 300, 400);
$dark-theme-background-accent: mat.define-palette($dark-theme-accent,
    100, 200, 300, 400);
$dark-theme-background-warn: mat.define-palette($dark-theme-warn,
    100, 200, 300, 400);

$dark-theme: mat.define-dark-theme((
    color: (
        primary: $dark-theme-background-primary,
        accent: $dark-theme-background-accent,
        warn: $dark-theme-background-warn
    )
));


// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.core-theme($light-theme);
@include app-component.theme($light-theme);
@include mat.toolbar-theme($light-theme);
@include mat.button-theme($light-theme);
@include mat.datepicker-theme($light-theme);
@include mat.input-theme($light-theme);
@include mat.form-field-theme($light-theme); 
@include mat.dialog-theme($light-theme);
@include mat.menu-theme($light-theme);
@include mat.tooltip-theme($light-theme);
@include mat.option-theme($light-theme);
@include mat.autocomplete-theme($light-theme);
@include mat.progress-spinner-theme($light-theme);
@include mat.slider-theme($light-theme);
@include mat.radio-theme($light-theme);

.dark-theme-mode {
    // @include mat.all-component-colors($dark-theme);
    @include mat.core-color($dark-theme);
    @include app-component.color($dark-theme);
    @include mat.toolbar-color($dark-theme);
    @include mat.button-color($dark-theme);
    @include mat.datepicker-color($dark-theme);
    @include mat.input-color($dark-theme);
    @include mat.form-field-color($dark-theme); 
    @include mat.dialog-color($dark-theme);
    @include mat.menu-color($dark-theme);
    @include mat.tooltip-color($dark-theme);
    @include mat.option-color($dark-theme);
    @include mat.autocomplete-color($dark-theme);
    @include mat.progress-spinner-color($dark-theme);
    @include mat.slider-color($dark-theme);
    @include mat.radio-color($dark-theme);
}

// * OTHER STUFF ---------------------------------------------------------------

/* You can add global styles to this file, and also import other style files */
html, body, div, canvas {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}
html, body { height: 100%;  margin: 0; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.box-shadow-outline {
    overflow: hidden;
    // KEEP TRACK OF THIS !
    border-radius: 3px;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.25);
}
