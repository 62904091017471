@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get colour config from the theme.
    $color-config: mat.get-color-config($theme);

    // Get the primary colour palette from the color-config
    $primary-palette: map.get($color-config, 'primary');
    $accent-palette: map.get($color-config, 'accent');
    $warn-palette: map.get($color-config, 'warn');

    // * General ---------------------------------------------------------------

    .background-colour {
        background-color: mat.get-color-from-palette($primary-palette, 100);
    }

    // Used for the various containers across the site
    .container-background-colour {
        background-color: mat.get-color-from-palette($primary-palette, 700);
        color: mat.get-color-from-palette($primary-palette, 700-contrast);
    }

    .mat-divider-soft-colour {
        color: mat.get-color-from-palette($primary-palette, 50-contrast);
    }

    // * User Inputs -----------------------------------------------------------
    .input-field-colour {
        background-color: mat.get-color-from-palette($primary-palette, 500);
        color: mat.get-color-from-palette($primary-palette, 500-contrast);
    }

    .input-field-subtext-color {
        color: mat.get-color-from-palette($accent-palette, 400);
    }

    // * Flat buttons ----------------------------------------------------------
    // Colour classes intended for the flat buttons throughout the platform
    .button-flat-colour {
        background-color: mat.get-color-from-palette($primary-palette, 500) !important;
        color: mat.get-color-from-palette($primary-palette, 500-contrast) !important;
    }

    .button-flat-colour-on {
        background-color: mat.get-color-from-palette($accent-palette, 300) !important;
        color: mat.get-color-from-palette($accent-palette, 300-contrast) !important;
    }

    .button-flat-colour:hover {
        background-color: mat.get-color-from-palette($accent-palette, 200) !important;
        color: mat.get-color-from-palette($accent-palette, 200-contrast) !important;
        cursor: pointer;
    }

    .button-flat-colour:disabled {
        background-color: mat.get-color-from-palette($primary-palette, 100) !important;
        color: mat.get-color-from-palette($primary-palette, 100-contrast) !important;
    }

    .button-flat-colour:focus {
        background-color: mat.get-color-from-palette($accent-palette, 200) !important;
        color: mat.get-color-from-palette($accent-palette, 200-contrast) !important;
        cursor: pointer;
    }

    .button-flat-colour-on:hover {
        cursor: pointer;
    }

    // * Toolbar buttons -------------------------------------------------------

    .toolbar-button-colour {
        background-color: mat.get-color-from-palette($primary-palette, 500);
        color: mat.get-color-from-palette($primary-palette, 500-contrast);
    }

    .toolbar-button-colour:hover {
        background-color: mat.get-color-from-palette($accent-palette, 200);
        color: mat.get-color-from-palette($accent-palette, 200-contrast);
        cursor: pointer;
    }

    .toolbar-button-colour:focus {
        background-color: mat.get-color-from-palette($accent-palette, 200);
        color: mat.get-color-from-palette($accent-palette, 200-contrast);
        cursor: pointer;
        outline: none;
    }

    .toolbar-button-colour-on {
        background-color: mat.get-color-from-palette($accent-palette, 300);
        color: mat.get-color-from-palette($accent-palette, 300-contrast);
    }

    .toolbar-button-colour-on:hover {
        background-color: mat.get-color-from-palette($accent-palette, 200);
        color: mat.get-color-from-palette($accent-palette, 200-contrast);
        cursor: pointer;
        outline: none;
    }

    .toolbar-button-colour-on:focus {
        background-color: mat.get-color-from-palette($accent-palette, 200);
        color: mat.get-color-from-palette($accent-palette, 200-contrast);
        outline: none;
    }

    // * Icon buttons ----------------------------------------------------------
    .button-icon-colour {
        color: mat.get-color-from-palette($primary-palette, 100-contrast);
    }

    .button-icon-colour-on {
        color: mat.get-color-from-palette($accent-palette, 100);
    }

    .button-icon-colour-on:hover {
        cursor: pointer;
    }

    .button-icon-colour:hover {
        color: mat.get-color-from-palette($accent-palette, 200);
        cursor: pointer;
    }

    .button-icon-colour:focus {
        color: mat.get-color-from-palette($accent-palette, 200);
    }

    // * Icon buttons w bg -----------------------------------------------------
    .button-icon-colour-w-bg {
        background-color: mat.get-color-from-palette($primary-palette, 500);
        color: mat.get-color-from-palette($primary-palette, 500-contrast);
        
    }

    .button-icon-colour-w-bg-on {
        background-color: mat.get-color-from-palette($accent-palette, 200);
        color: mat.get-color-from-palette($accent-palette, 200-contrast);
    }

    .button-icon-colour-w-bg-on:hover {
        cursor: pointer;
    }

    .button-icon-colour-w-bg:hover {
        background-color: mat.get-color-from-palette($accent-palette, 200);
        color: mat.get-color-from-palette($accent-palette, 200-contrast);
        cursor: pointer;
    }

    .button-icon-colour-w-bg:focus {
        background-color: mat.get-color-from-palette($accent-palette, 200);
        color: mat.get-color-from-palette($accent-palette, 200-contrast);
    }



}


@mixin typography($theme) {

}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }
}
